import router from "@/router/index"
import store from "@/store"
import { initUserInfo } from "@/views/Login"; // 导入获取用户信息和菜单列表的 API 方法

/**
 * Load the dynamic menu on the left side of this page
 * 全局导航守卫    beforeEach方法用于设置全局导航守卫，检查用户是否已登录，如果未登录并且访问的不是登录页面，则重定向到登录页面。
 */
router.beforeEach((to,from,next)=>{
    const whiteList=['/login', '/', '/home', '/m', '/mobile','/OfficialSoft','/OfficialProgram','/OfficialOrg','/OfficialIot','/m/*'];// 白名单
    let token = store.getters.GET_TOKEN;
    let hasRoutes = store.state.hasRoutes;
    let menuList = store.getters.GET_MENULIST;
    if(token){
        if(!hasRoutes){
            bindRoute(menuList);
            store.commit("SET_ROUTES_STATE",true);
            return next({path:'/index'});   //解决页面刷新空白问题。
        }
        return next();
    }else{
        if(whiteList.includes(to.path)){
            //console.log("to.path5555:",to.path);   //输入localhost时，这里打印出来的结果是 “/login”
            //如果未登录，但要访问的是白名单中的页面，则直接放行
            return next();
        }else{
            //console.log("to.path66:",to.path);
            // 跳转到登录页面
            //console.log("next():4::::",next());
            return next("/login")
            //next(`/login?redirect=${to.path}`);
        }
    }
});

// 动态绑定路由
const bindRoute=(menuList)=>{
    let newRoutes=router.options.routes;
    menuList?.forEach(menu=>{
        if(menu.children){
            menu.children.forEach(m=>{
                let route=menuToRoute(m,menu.menuName);
                if(route){
                    newRoutes[0].children.push(route);
               }
            })
        }
    });
    // 重新添加到路由  （对象.属性，如果对象为null会报错，解决对象后面加问号如下）
    // If the menu type is  "菜单" or "目录", the component path cannot be empty.
    newRoutes.forEach(route=>{
        router.addRoute(route)
    })
};

// 菜单对象转成路由对象
const menuToRoute=(menu,parentName)=>{
    if(!menu.component){
        return null;
    }else{
        let route={
            name:menu.menuName,
            path:menu.realmUrl,
            meta:{
                parentName:parentName
            }
        };
        route.component=()=>import('@/views/'+menu.component+'.vue');
        //console.log("menu==========是"+JSON.stringify(menu));
        //console.log("跳转的路由对象是"+JSON.stringify(route));
        return route;
    }
};
