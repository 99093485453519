// 引入axios
import axios from 'axios';
import store from '@/store'

/*这里的baseUrl设置在了vue.config.js里了*/
let baseUrl="/api";
//let baseURL2 = process.env.VUE_APP_BASE_API;
//let baseUrl="http://localhost:8003/";
// 创建axios实例
const httpService = axios.create({
    // url前缀-'http:xxx.xxx'
    // baseURL: process.env.BASE_API, // 需自定义
    baseURL:baseUrl,
    // 请求超时时间
    timeout: 30000 // 需自定义
});

//添加请求和响应拦截器
// 添加请求拦截器
httpService.interceptors.request.use(function (config) {
    //添加请求头
    config.headers.token=store.getters.GET_TOKEN;
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
httpService.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    if (error.response && error.response.data.status === 401) {
        // token续签方式1:
        //清空当前vuex保存的token（我们这的vuex和本地已经建立了关系，相当于也清空了本地token）
        store.commit('upUser', '');
        // 当前路由的完整路径（#后面的）
        console.log(router.currentRoute.fullPath);
        //这里我们采用?path=的方式保存当前浏览页面的完整路径，
        // push()会产生历史记录 而replace不会有历史记录
        router.push({ path: `/login?path=${router.currentRoute.fullPath}` })
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'get',
            params: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export function getServerUrl(){
    return baseUrl;
}

export default {
    get,
    post,
    fileUpload,
    getServerUrl
}
