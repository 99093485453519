import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/black',
    name: '系统首页',
    component: () => import('../layout'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: '首页',
        component: () => import('../views/index/index')
      },
      {
        path: '/userCenter',
        name: '个人中心',
        component: () => import('../views/userCenter/index')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    mate: {
      title: 'App开发_软件开发_小程序开发_软件定制开发公司',
    },
    component: () => import('../views/official/OfficialHome.vue')
  },
  {
    path: '/OfficialSoft',
    name: 'OfficialSoft',
    component: () => import('../views/official/OfficialSoft.vue')
  },
  {
    path: '/OfficialProgram',
    name: 'OfficialProgram',
    component: () => import('../views/official/OfficialProgram.vue')
  },
  {
    path: '/OfficialOrg',
    name: 'OfficialOrg',
    component: () => import('../views/official/OfficialOrg.vue')
  },
  {
    path: '/OfficialIot',
    name: 'OfficialIot',
    component: () => import('../views/official/OfficialIot.vue')
  },
  {
    path: '/',
    redirect: () => {
      const userAgent = window.navigator.userAgent;
      if (/iPhone|iPod|Android|Windows Phone/.test(userAgent)) {
        return '/m';
      } else {
        return '/home';
      }
    }
  },
  {
    path: '/m',
    redirect: '/mobile'
  },
  {
    path: '/mobile',
    name: 'MobileHome',
    component: () => import('../views/official/m/mhome.vue')
  },
  {
    path: '/desktop',
    name: 'DesktopHome',
    component: () => import('../views/official/OfficialHome.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router
