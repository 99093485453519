import { createStore } from 'vuex'
import router from '@/router'

/**vuex相关存储数据的地方*/
export default createStore({
  state: {
    hasRoutes:false,
    editableTabsValue:'/index',
    editableTabs:[{
        title:'首页',
        name:'/index',
        close:false  //首页界面”开启且不可关闭
      }]
  },
  getters: {
    GET_TOKEN:state => {
      return sessionStorage.getItem("token")
    },
    GET_MENULIST:state => {
      return JSON.parse(sessionStorage.getItem("menuList"));
    },
    GET_USERINFO:state => {
      return JSON.parse(sessionStorage.getItem("userInfo"));
    },
    GET_PERMS:state => {
      return JSON.parse(sessionStorage.getItem("perms"));
    }
  },
  mutations: {
    SET_TOKEN:(state,token)=>{
      sessionStorage.setItem("token",token);
    },
    SET_MENULIST:(state,menuList)=>{   //这里的参数menuList是在调用SET_MENULIST时传入的：（ store.commit('SET_MENULIST',menuList); )  //注意增加了菜单需要在后台增加权限菜单才可见
      sessionStorage.setItem("menuList",JSON.stringify(menuList));
    },
    SET_USERINFO:(state,userInfo)=>{
      sessionStorage.setItem("userInfo",JSON.stringify(userInfo));
    },
    SET_PERMS:(state,perms)=>{      //20230317  22:13
      sessionStorage.setItem("perms",JSON.stringify(perms));
    },
    SET_ROUTES_STATE:(state,hasRoutes)=>{
      state.hasRoutes=hasRoutes
    },
    ADD_TABS:(state,tab)=>{
      if(state.editableTabs.findIndex(e=>e.name===tab.path)===-1){
        state.editableTabs.push({
          title:tab.name,
          name:tab.path
        })
      }
      state.editableTabsValue=tab.path
    },
    RESET_TABS:(state)=>{
      state.editableTabsValue='/index';
      state.editableTabs=[
        {
          title:'首页',
          name:'/index'
        }
      ]
    },
  },
  actions: {
    // 安全退出
    logout(){
      window.sessionStorage.clear();
      router.replace("/login")
    }
  },
  modules: {
  }
})
